<template>
  <dropdown-select :items="dropdownItems" :value="currentIndex" :disabled="disabled" :is-form-input="isFormInput" @input="onNewSelection">
    <template #dropdown="{ item }">
      <span>{{ getLabelForItem(item) }}</span>
    </template>

    <template #placeholder>
      <span class="dropdown-text is-placeholder" v-if="placeholder">{{ placeholder }}</span>
      <span class="dropdown-text is-placeholder" v-else>{{ $t("common.select_from_list") }}</span>
    </template>
  </dropdown-select>
</template>

<script>
import DropdownSelect from "@/shared/components/DropdownSelect";

export default {
  name: "DropdownSelectWrapper",
  components: { DropdownSelect },

  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {
      required: false,
    },
    itemLabelFunction: {
      type: Function,
      required: false,
    },
    itemValueFunction: {
      type: Function,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    unselectable: {
      type: Boolean,
      default: true,
    },
    isFormInput: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentIndex: null,
    };
  },

  computed: {
    dropdownItems() {
      if (this.unselectable) {
        return [this.$t("common.clear_selection"), ...this.items];
      } else {
        return this.items;
      }
    },
  },

  methods: {
    onNewSelection(index) {
      this.currentIndex = this.hasOffset() && index === 0 ? this.getIndexOffset() : index;
      this.$emit("input", this.getValueAtIndex(index));
    },

    getValueAtIndex(index) {
      const finalIndex = index + this.getIndexOffset();
      if(finalIndex >= 0 && finalIndex < this.items.length) {
        return (this.itemValueFunction && this.itemValueFunction(this.items[finalIndex])) || this.items[finalIndex];
      } else {
        return null;
      }
    },

    hasOffset() {
      return this.getIndexOffset() !== 0;
    },

    getIndexOffset() {
      return this.unselectable ? -1 : 0;
    },

    getLabelForItem(item) {
      return (this.itemLabelFunction && this.itemLabelFunction(item)) || item;
    }
  },

  watch: {
    value: {
      immediate: true,
      handler: function (newValue) {
        if (typeof newValue === "undefined" || newValue === null) {
          this.currentIndex = -1;
        } else {
          let values = Object.values(this.dropdownItems);
          let mappedValues = (this.itemValueFunction && values.map(this.itemValueFunction)) || values;
          this.currentIndex = mappedValues.indexOf(newValue);
        }
      },
    },
  },
};
</script>

<style scoped></style>
